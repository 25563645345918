<template>
  <div class="view-home">
    <div class="centers">
      <div class="container">
        <nav class="breadcrumbs-block">
          <ul class="breadcrumbs-list">
            <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
            <li class="breadcrumbs-item">{{ $t('regions.subtitle') }}</li>
          </ul>
        </nav>
        <div class="row centers-main">
          <div class="title">
            {{ $t('regions.subtitle') }}
          </div>
<!--          <div class="phone">-->
<!--            {{ selectedRegion.phones.replace(/{|}/g, '') }}-->
<!--          </div>-->
          <div class="phone">
            <a :href="'tel:' + extractNumbers(item)" v-for="item in phoneArray(selectedRegion.phones)">
              {{item}}
            </a>
          </div>

          <div class="location">
            {{ selectedRegion[`address_${$i18n.locale}`] }}
          </div>
        </div>
        <div class="choose-region">
          {{ $t('selectYourRegion') }}
        </div>
      </div>
    </div>
    <div class="container">
      <section class="addresses-centers addresses-centers-block">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-5 col-12 section_text">
              <select class="selectRegion" id="selectRegion" @change="selectRegion">
                <option v-for="region in regions" :key="region.id" :value="region.id" >{{region.name}}</option>
              </select>
              <!--            <div v-show="!hideElements" class="card" v-if="selectedRegion">-->
              <!--              <div>{{ $t('regions.address') + selectedRegion[`address_${$i18n.locale}`] }}</div>-->
              <!--              <div v-if="!!selectedRegion.phones">{{ $t('regions.phone') + selectedRegion.phones.replace(/{|}/g, '') }}</div>-->
              <!--              <div v-if="selectedRegion.email !== 'NULL'">{{ $t('regions.email') + selectedRegion.email}}</div>-->
              <!--            </div>-->

              <!--                <div v-show="!hideElements" class="address" v-if="selectedRegion">-->
              <!--                  <span>{{ selectedRegion[`address_${$i18n.locale}`] }}, </span>-->
              <!--                  <span v-if="!!selectedRegion.phones">{{ selectedRegion.phones.replace(/{|}/g, '') }}, </span>-->
              <!--                  <span v-if="selectedRegion.email !== 'NULL'">{{ selectedRegion.email}}</span>-->
              <!--                </div>-->

              <div v-show="!hideElements" class="address" v-if="selectedRegion">
              <span>
                <img src="/images/maps/point.svg" width="25" height="25" alt=""/>
                {{ selectedRegion[`address_${$i18n.locale}`] }}, </span>
                <span v-if="!!selectedRegion.phones">
                <img src="/images/maps/phone.svg" width="25" height="25" alt=""/>
                <a :href="'tel:' + extractNumbers(item)" v-for="item in phoneArray(selectedRegion.phones)">
                    {{ item }}
                </a>
              </span>
                <span v-if="selectedRegion.email !== 'NULL'">
                <img src="/images/maps/mail.svg" width="25" height="25" alt=""/>
                <a :href="`mailto:` + selectedRegion.email">{{ selectedRegion.email}}</a>
              </span>
              </div>

              <div class="for-communication-mobile">
                <div class="phone">
                  {{ selectedRegion.phones.replace(/{|}/g, '') }}
                </div>
                <div class="location">
                  {{ selectedRegion[`address_${$i18n.locale}`] }}
                </div>
              </div>

              <router-link :to="getTestedLink" class="link">
                {{ $t('regions.test-btn') }}
              </router-link>

              <!--                <a class="link">-->
              <!--                  Записаться на консультацию-->
              <!--                </a>-->
            </div>
            <div class="col-xl-7 col-12">
              <yandex-map
                  zoom="4"
                  style="width: 100%; max-width: 100%; height: 385px;"
                  :coords="coords"
              >
                <ymap-marker
                    v-for="(billboard, index) in surfacesFilter"
                    marker-type="placemark"
                    :balloon-template="mapBalloon(billboard, index)"
                    :coords="[billboard.latitude, billboard.longitude]"
                    :marker-id="index"
                    :key="index"
                ></ymap-marker>
              </yandex-map>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>
</template>
<script>
import {yandexMap, ymapMarker} from "vue-yandex-maps";
import pathsRu from "@/plugins/paths/ru";
import pathsKz from "@/plugins/paths/kz";

export default {
  components: {yandexMap, ymapMarker},
  data() {
    return {
      getTestedLink: localStorage.getItem('token') ? "/testing" : "/signin",
      logged: false,
      hideElements:true,
      pathFile: {},
      regionsLoading: false,
      regions: [],
      selectedRegionName: this.$i18n.locale === 'ru' ? 'Выберите свой регион' : 'Өз аймағыңызды таңдаңыз',
      selectedRegion: null,
      coords: [48.005284, 66.9045434],
      selectedSurfaces: this.$selectedSurfaces,
      surfaces: [],
      surfacesFilter:[],
    }
  },
  methods: {
    extractNumbers(str) {
      return str.replace(/\D/g, '');
    },
    phoneArray(str) {
      let cleanedString = str.replace(/[{}]/g, '').trim();
      return cleanedString.split(',').map(phone => phone.trim());
    },
    selectedBillboard(billboard) {
      let data = {
        SURFACEID: billboard.surface_id,
        NETWORKID: billboard.networkid
      };
      return data;
    },
    makeSurfaceSelected: function(surface_id) {
      this.surfaces.forEach(surface => {
        if (surface.surface_id === surface_id) {
          surface.selected = true;
        }
      });
    },
    mapBalloon: function(billboard, index) {
      if(this.$i18n.locale === 'ru'){
        return `
            <p><strong>Город</strong>: ${billboard.city_ru}</p>
            <p><strong>Адрес</strong>: ${billboard.address_ru}</p>
            </div>
            `
      }
      else if(this.$i18n.locale === 'kz'){
        return `
            <p><strong>Қаласы</strong>: ${billboard.city_kz}</p>
            <p><strong>Мекен жайы</strong>: ${billboard.address_kz}</p>
            </div>
            `
      }
    },
    checkLanguage() {
      switch (this.$i18n.locale) {
        case 'ru':
          this.pathFile = pathsRu;
          break;
        case 'kz':
          this.pathFile = pathsKz;
          break;
        case 'en':
          this.pathFile = pathsRu;
          break;
        default:
          this.pathFile = pathsRu;
      }
    },
    selectRegion(event) {
      const { value } = event.target
      if(+value - 1==-1){
        this.hideElements=true
        this.surfacesFilter=this.surfaces
        return
      }
      this.selectedRegionName = this.surfaces[+value - 1][`region_${this.$i18n.locale}`];
      this.selectedRegion = this.surfaces[+value - 1]



      for (var surface=0; surface<this.surfaces.length; surface++ ){
        if(this.surfaces[surface].id===this.selectedRegion.id){
          this.hideElements=false
          this.surfacesFilter=[]
          this.surfacesFilter.push(this.surfaces[surface])
        }
      }

    },
    async getSurfaces() {
      try {
        this.regionsLoading = true;
        const res = await this.$http.get(window.API_ROOT + '/api/references/regional-centers')
        if (res.data.data) {
          this.surfaces = res.data.data
          this.surfacesFilter=this.surfaces

          for (let i = 0; i < res.data.data.length; i++) {
            const element = res.data.data[i];
            this.regions.push({
              id: element.id,
              name: element[`region_${this.$i18n.locale}`]
            })
          }
          this.regions.unshift(
              {
                id: 0,
                name: this.$t('main-page.show-all')
              }
          )
          //<option  :key="99" :value="99" >{{ $t('main-page.show-all')}}</option>
          this.selectedRegionName = this.surfaces[0][`region_${this.$i18n.locale}`];
          this.selectedRegion = this.surfaces[0]
          console.log('this.selectedRegionName', this.selectedRegionName)
          console.log('this.selectedRegion', this.selectedRegion)

          for (var surface=0; surface<this.surfaces.length; surface++ ){
            if(this.surfaces[surface].id===this.selectedRegion.id){
              this.hideElements=false
              this.surfacesFilter=[]
              this.surfacesFilter.push(this.surfaces[surface])
            }
          }
        }
      } catch (error) {
        Vue.toastr({
          message: '',
          description: `${error}`,
          type: 'error'
        });
      } finally {
        this.regionsLoading = false
      }
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');
    this.checkLanguage();
    this.regionsLoading = true;
    this.getSurfaces()
  },
}
</script>

<style>
.for-communication-mobile {
  display: none;
}

.section_text {
  margin-bottom: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.selectRegion {
  background: #F9F9F9;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 11px 23px;
  width: 100%;
  margin-bottom: 40px;
  letter-spacing: 0.2px;
  color: #343F68;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  font-family: "Roboto Condensed";
  font-style: normal;
  outline: none;
  width: calc(100% - 60px);
  cursor: pointer;
}
.selectRegion::after {
  content: url('/images/arrow-down.svg');
  position: absolute;
  top: 13px;
  right: 20px;
  z-index: 5;
}
.btn-blue {
  color: #FFFFFF;
  background: #04C2F4;
  border-radius: 5px;
}

.addresses-centers-block {

}

@media screen and (max-width: 992px) {

  .for-communication-mobile {

  }

  .for-communication-mobile .phone {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    font-weight: 600;
    color: #32436D;
  }

  .for-communication-mobile .phone::before {
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background-image: url("/images/icon_call.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .for-communication-mobile .location {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    font-weight: 600;
    color: #32436D;
  }

  .for-communication-mobile .location::before {
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background-image: url("/images/icon_location.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .centers-main .phone {
    display: none;
  }

  .centers-main .location {
    display: none;
  }

  .centers-main .title {
    width: 100%;
  }

  .choose-region {
    display: none;
  }

  .centers {
    height: 500px;
    min-height: 500px;
  }

  .addresses-centers-block {
    margin-top: -250px;
  }

  .addresses-centers-block .section_text h2 {
    margin-left: 0px;
  }

  .addresses-centers-block .section_text::before {
    top: 30px;
  }

  .addresses-centers-block .section_text .selectRegion {
    margin-left: 0px;
    width: 100%;
  }

  .addresses-centers-block .section_text {
    padding-top: 70px;
  }

  #mapTitle {
    margin-top: 40px;
  }
}

@media screen and (max-width: 410px) {
  .addresses-centers-block {
    margin-top: -200px;
  }
}

</style>